import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { HttpClientModule } from '@angular/common/http';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { BLE } from '@ionic-native/ble/ngx';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Observable } from 'rxjs';
import { IMqttMessage, MqttModule, IMqttServiceOptions } from 'ngx-mqtt';
import { TraduzioniService } from './api/traduzioni.service';


export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: '80.211.86.231',
  protocol: 'ws',
  port: 1884,
  path: '/mqtt'
};

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({animated: false}), //disabilito le animazioni nel cambio pagina
    AppRoutingModule,
    FormsModule,
    NgxExtendedPdfViewerModule,             //importo la classe per visualizzare i PDF
    HttpClientModule,
    IonicStorageModule.forRoot(),
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, BarcodeScanner, BLE],
  bootstrap: [AppComponent],
})

export class AppModule {}
