export const urlServer= 'https://www.krupps.app';

export const  urlGetStock= urlServer + '/krapps/app/k_stock_list.php';
export const  urlGetDishwasher = urlServer + '/krapps/app/k_item_getDishwashers.php';
export const  urlGetItems = urlServer + '/krapps/app/k_item_list_ric.php';
export const  urlDiba = urlServer + '/krapps/app/k_diba.php';
export const  urlVersionItem = urlServer + '/krapps/app/k_service_bom_list_.php';
export const  urlMatricolaModello = urlServer + '/krapps/app/k_service_matricola_modello.php';
export const  urlMac = urlServer + '/krapps/app/k_service_mac.php';
export const  urlDeviceRemove = urlServer + '/krapps/app/k_device_remove.php';
export const  urlDeviceHide = urlServer + '/krapps/app/k_device_hide.php';
export const  urlDeviceAdd = urlServer + '/krapps/app/k_device_add.php';
export const  urlDeviceShare = urlServer + '/krapps/app/k_device_share.php';
export const  urlDeviceEdit = urlServer + '/krapps/app/k_device_edit.php';
export const  urlWebTraduzioni = urlServer + '/krapps/app/k_traduzioni_.php';
export const  urlWebLinguaList = urlServer + '/krapps/app/k_lingua_list.php';
export const  urlGetItemPromoList = urlServer + '/krapps/app/k_promo_list.php';
export const  urlUser = urlServer + '/krapps/app/k_account_login2.php';
export const  urlUserDetails = urlServer + '/krapps/app/k_account_details.php';
export const  urlCountries = urlServer + '/krapps/app/k_countries_list.php';
export const  urlSetNewUser = urlServer + '/krapps/app/k_account_new.php';
export const  urlLicenza = urlServer + '/krapps/app/k_licenza_attiva.php';
export const  urlDocumentListService = urlServer + '/krapps/app/k_document_list_service.php';
export const  urlAccountUser = urlServer + '/krapps/app/k_account_users.php';
export const  urlAccountLanguage = urlServer + '/krapps/app/k_lingua_account.php';
export const  urlAccountCountry = urlServer + '/krapps/app/k_account_country.php';
export const  urlAccountPermission = urlServer + '/krapps/app/k_account_permission.php';
export const urlGetCartNumber = urlServer + '/krapps/app/k_cart_list.php';
export const urlAddCart = urlServer + '/krapps/app/k_cart_add.php';
export const urlDeleteCart = urlServer + '/krapps/app/k_cart_delete.php';
export const urlEditCart = urlServer + '/krapps/app/k_cart_edit.php';
export const urlProcessaCart = urlServer + '/krapps/app/k_cart_processa.php';
export const urlGetConfig = urlServer + '/krapps/app/k_configuratore.php';
export const urlGetBomService = urlServer + '/krapps/app/k_bom_pdf.php';
export const urlGetPdfSpec = urlServer + '/krapps/app/_scheda_item.php';
export const urlGetDocumenti = urlServer + '/krapps/app/k_document_list.php';
export const urlGetNews = urlServer + '/krapps/app/k_news_list.php';
export const urlLog = urlServer + '/krapps/app/k_log_add.php';
export const urlViewerShare = urlServer + '/krapps/app/k_viewer_share.php';
export const urlGetPdfServiceLog = urlServer + '/krapps/app/k_report_serviceLog.php';
export const urlGetPdf24hLog = urlServer + '/krapps/app/k_report_24hLog.php';
export const urlAccountAuthCode = urlServer + '/krapps/app/k_account_authCode.php';
export const urlAccountResendAuthCode = urlServer + '/krapps/app/k_account_resendAuthCode.php';
export const urlAccountResetPassword = urlServer + '/krapps/app/k_account_resetPassword.php';
export const urlAccountContactAccount = urlServer + '/krapps/app/k_account_contactAccount.php';
export const urlAccountEnableAccount = urlServer + '/krapps/app/k_account_enableAccount.php';
export const urlSupport = urlServer + '/krapps/app/k_support.php';
export const urlAccountResetPartner = urlServer + '/krapps/app/k_account_resetPartner.php';
