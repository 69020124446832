import { Component } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { WebService  } from './api/web.service';
//import { TraduzioniService  } from './api/traduzioni.service';
import { User } from './api/oggetti/user.model';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  users: User [] = [];
  name: string;
  email: string;
  language: string;
  token: string;
  password: string;
  country: string;
  appManager: boolean;
  appService: boolean;
  appPrice: boolean;
  appNetto: boolean;
  appCart: boolean;

  public traduzioni: any[];
  menu: {title; url; icon};
  public appPages = [];
  linguaDevice= 'ENGLISH';
  public now: number;

  constructor(

    private platform: Platform,
    public alertController: AlertController,
    private webService: WebService,
    //private traduzioniService: TraduzioniService,
  ) { this.initializeApp();

  }


  async initializeApp(){
    this.traduzioni =['init'];
    /*  this.platform.ready().then(() => { window.screen.orientation.lock('portrait');});   */
  }


public traduzioneMenu(trad)
{
  this.traduzioni = trad;
  console.log('APP- ' + this.traduzioni[0].folder_menuHome);
  this.menu = {title: this.traduzioni[0].folder_menuHome, url: '/principale', icon:'home' };
  this.appPages.push(this.menu);
  this.menu = {title: this.traduzioni[0].folder_menuProfilo, url: '/user', icon:'person' };
  this.appPages.push(this.menu);
  this.menu = {title: this.traduzioni[0].folder_menuPermessi, url: '/autorizzazioni', icon:'people' };
  this.appPages.push(this.menu);
  this.menu = {title: this.traduzioni[0].folder_menuLingua, url: '/lingua', icon:'heart' };
  this.appPages.push(this.menu);
  }

}


