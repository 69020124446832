import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  {
    path: '',
    redirectTo: 'splashscreen',
    pathMatch: 'full'
  },
  {
    path: 'items',
    loadChildren: () => import('./items/items/items.module').then( m => m.ItemsPageModule)
  },
  {
    path: 'item',
    loadChildren: () => import('./items/item/item.module').then( m => m.ItemPageModule)
  },
  {
    path: 'catalogo',
    loadChildren: () => import('./items/catalogo/catalogo.module').then( m => m.CatalogoPageModule)
  },
  {
    path: 'viewer',
    loadChildren: () => import('./viewer/viewer.module').then( m => m.ViewerPageModule)
  },
  {
    path: 'news',
    loadChildren: () => import('./news/news.module').then( m => m.NewsPageModule)
  },
  {
    path: 'servicepage',
    loadChildren: () => import('./servicepage/servicepage.module').then( m => m.ServicepagePageModule)
  },
  {
    path: 'autentificazione',
    loadChildren: () => import('./login/autentificazione/autentificazione.module').then( m => m.AutentificazionePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./login/privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'registrazione',
    loadChildren: () => import('./login/registrazione/registrazione.module').then( m => m.RegistrazionePageModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('./cart/cart.module').then( m => m.CartPageModule)
  },
  {
    path: 'splashscreen',
    loadChildren: () => import('./splashscreen/splashscreen.module').then( m => m.SplashscreenPageModule)
  },
  {
    path: 'principale',
    loadChildren: () => import('./principale/principale.module').then( m => m.PrincipalePageModule)
  },
  {
    path: 'blscan',
    loadChildren: () => import('./connessioni/bluetooth/blscan/blscan.module').then( m => m.BlscanPageModule)
  },
  {
    path: 'mqtt',
    loadChildren: () => import('./connessioni/mqtt/mqtt.module').then( m => m.MqttPageModule)
  },
  {
    path: 'confmenu',
    loadChildren: () => import('./configuratore/confmenu/confmenu.module').then( m => m.ConfmenuPageModule)
  },
  {
    path: 'confmisure',
    loadChildren: () => import('./configuratore/confmisure/confmisure.module').then( m => m.ConfmisurePageModule)
  },
  {
    path: 'conftipologia',
    loadChildren: () => import('./configuratore/conftipologia/conftipologia.module').then( m => m.ConftipologiaPageModule)
  },
  {
    path: 'confcesto',
    loadChildren: () => import('./configuratore/confcesto/confcesto.module').then( m => m.ConfcestoPageModule)
  },
  {
    path: 'confrange',
    loadChildren: () => import('./configuratore/confrange/confrange.module').then( m => m.ConfrangePageModule)
  },
  {
    path: 'mqtt',
    loadChildren: () => import('./connessioni/mqtt/mqtt.module').then( m => m.MqttPageModule)
  },
  {
    path: 'blscan',
    loadChildren: () => import('./connessioni/bluetooth/blscan/blscan.module').then( m => m.BlscanPageModule)
  },
  {
    path: 'lingua',
    loadChildren: () => import('./settaggi/lingua/lingua.module').then( m => m.LinguaPageModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./settaggi/user/user.module').then( m => m.UserPageModule)
  },
  {
    path: 'autorizzazioni',
    loadChildren: () => import('./settaggi/autorizzazioni/autorizzazioni.module').then( m => m.AutorizzazioniPageModule)
  },
  {
    path: 'specialfunction',
    loadChildren: () => import('./settaggi/specialfunction/specialfunction.module').then( m => m.SpecialfunctionPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
