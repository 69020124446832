import { Injectable, ErrorHandler } from '@angular/core';
import { Promo } from './oggetti/promo.model';
import { Cart } from './oggetti/cart.model';
import { User } from './oggetti/user.model';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Storage } from '@ionic/storage-angular';
import { Data } from '@angular/router';
import { Router } from '@angular/router';
import  * as Url from './oggetti/url.model';

export class Profilo {
  email: string;
  password: string;
  token: string;
  appUserType: string;
  appManager: boolean;
  appService: boolean;
  appPrice: boolean;
  appNetto: boolean;
  appCart: boolean;
}

export class Pdf {
  url: string;
  titolo: string;
}


export class Credenziali {
  email: string;
  password: string;
  name: string;
  language: string;
  appManager: boolean;
  appService: boolean;
  appPrice: boolean;
  appNetto: boolean;
  appCart: boolean;
  token: string;
  appuser: string;
  utentePermessi: string;
  country: string;
  countMac: number;
}

export class Licenza {
  mesi: number;
  tipo: number;
}

export class Abilita {
  success: number;
}

@Injectable({
  providedIn: 'root'
})



export class WebService {

  credenziali: Credenziali;
  user: User[] = [];
  promozioni: Promo[] = [];
  items: Promo[] = [];
  licenza: Licenza[];
  pdf: [Pdf];
  utente: any;
  public utente2: any;

  appVersione: any;
  appVersioneNumber: any;

  myUser: string;
  myToken: string;
  myPassword: string;
  myLanguage: string;
  myCountry: string;
  myPermessi: string;
  myReady: number;

  myUser2: string;
  myToken2: string;
  myPassword2: string;

  constructor(
    private httpClient: HttpClient,
    private storage: Storage,
    private router: Router
    )
    {  }

   async init() {
      // If using, define drivers here: await this.storage.defineDriver(/*...*/);
      console.log('SERVICE --> INIT pagina SERVICE');
      const storage = this.storage.create();
      console.log('SERVICE --> Creato Storage');

      //Recupero credenziali dallo storage
      this.myUser2 = await this.storage.get('email');
      this.myToken = await this.storage.get('appToken');
      this.myPassword = await this.storage.get('password');
      this.myLanguage = await this.storage.get('language');
      this.myCountry = await this.storage.get('country');
      this.myPermessi = await this.storage.get('utentePermessi');

      //Le inserisco su l'oggetto credenziali
      this.credenziali= {
        email: this.myUser2,
        token: this.myToken,
        password: this.myPassword,
        country: this.myCountry,
        language: this.myLanguage,
        name: '',
        appManager: false,
        appService: false,
        appPrice: false,
        appNetto: false,
        appCart: false,
        appuser: '',
        utentePermessi: this.myPermessi,
        countMac: 2
      };

      console.log('SERVICE USER --> '+this.myUser2+' - '+this.myToken+' - '+this.myPassword +' - '+this.myLanguage+' - '+this.myCountry);

  }

  public setStorage(key: string, value: any) {
    this.storage.set(key, value);
    if(key === 'language'){this.myLanguage = value;}
    if(key === 'country') {this.myCountry = value;}
    if(key === 'utentePermessi') {this.myPermessi = value;}
    console.log('settato ' + key + ': '+ value);
  }

  async getStorage(key: string) {
    return await this.storage.get(key).then((val) => {
    	console.log('Recupero Storage --> ' +key+ ': ' + val);
      if(key === 'language'){this.myLanguage = val;}
      if(key === 'country') {this.myCountry = val;}
      if(key === 'utentePermessi') {this.myPermessi = val;}
        return val; // <--- good result
    });
}

setVersione(version, versionNumber){
  this.appVersione = version;
  this.appVersioneNumber = versionNumber;
}

getVersione(){return this.appVersione  +' - '+ this.appVersioneNumber;}

getUtente() { return this.myUser; }

getCredenziali() { return this.credenziali; }

//setCredenziali(){}

  getLogin(username,password): Observable<User[]> {
    let params = new HttpParams();
    params = params.append('username', username);
    params = params.append('password', password);
      return this.httpClient.get<User[]>(Url.urlUser, { params })
        .pipe( tap ( login => {
          if(login[0].success === 1 ){
            this.myUser=username;
            this.myUser2=username;
            //Scrivo sullo storage le credenziali
            this.setStorage('email', login[0].email);
            this.setStorage('password', password);
            this.setStorage('name', login[0].name);
            this.setStorage('language', login[0].language);
            this.setStorage('appManager', login[0].appManager);
            this.setStorage('appService', login[0].appService);
            this.setStorage('appPrice', login[0].appPrice);
            this.setStorage('appNetto', login[0].appNetto);
            this.setStorage('appCart', login[0].appCart);
            this.setStorage('appToken', login[0].token);
            this.setStorage('appuser', login[0].appuser);
            this.setStorage('country', login[0].country);
            this.setStorage('utentePermessi',login[0].utentePermessi);
            //Metto le credenziali su iggetto credenziali
            this.credenziali.email = login[0].email;
            this.credenziali.password = login[0].password;
            this.credenziali.name = login[0].name;
            this.credenziali.language = login[0].language;
            this.credenziali.country = login[0].country;
            this.credenziali.appManager = login[0].appManager;
            this.credenziali.appService = login[0].appService;
            this.credenziali.appPrice = login[0].appPrice;
            this.credenziali.appNetto = login[0].appNetto;
            this.credenziali.appCart = login[0].appCart;
            this.credenziali.token = login[0].token;
            this.credenziali.appuser = login[0].appuser;
            this.credenziali.utentePermessi = login[0].utentePermessi;
            this.credenziali.countMac = login[0].macCount;
            console.log('SERVICE --> Login Function success '+ this.credenziali.email +  ' - ' + this.credenziali.countMac);
          }
        }),
          catchError(this.handleError<User[]>('Get user ${id}'))
        );
    }

    getUserDetails(): Observable<User[]> {
      let params = new HttpParams();
      params = params.append('user', this.myUser);
        return this.httpClient.get<User[]>(Url.urlUserDetails, { params })
          .pipe( tap ( _ => console.log(_)),
            catchError(this.handleError<User[]>('Get user ${id}'))
          );
      }

  setNewUser(email,nome,cognome,piva, password,appuser,country, lingua): Observable<User[]> {
      let params = new HttpParams();
      params = params.append('email', email);
      params = params.append('nome', nome);
      params = params.append('cognome', cognome);
      params = params.append('piva', piva);
      params = params.append('password', password);
      params = params.append('appuser', appuser);
      params = params.append('country', country);
      params = params.append('language', lingua);
        return this.httpClient.get<User[]>(Url.urlSetNewUser, { params })
          .pipe(
            tap ( _ => console.log( _ )),
            catchError(this.handleError<User[]>('Get user ${id}'))
          );
      }


   async getLogout() {
     await this.storage.clear();
     //this.credenziali= null;
     this.credenziali.email = '';
     this.credenziali.password = '';
     this.credenziali.name = '';
     this.credenziali.language = '';
     this.credenziali.country = '';
     this.credenziali.appManager = false;
     this.credenziali.appService = false;
     this.credenziali.appPrice = false;
     this.credenziali.appNetto = false;
     this.credenziali.appCart = false;
     this.credenziali.token = '';
     this.credenziali.appuser = '';
     this.credenziali.utentePermessi = '';
     this.credenziali.countMac = 0;
     console.log('FATTO LOGOUT!!!!!!');
    this.router.navigate(['/autentificazione' , {}]);
      }

      getAccountUsers(): Observable<User[]> {
        let params = new HttpParams();
        params = params.append('user', this.myUser);
          return this.httpClient.get<User[]>(Url.urlAccountUser , { params })
            .pipe(
              tap (_ => console.log( _ )),
              catchError(this.handleError<User[]>('Get user ${id}'))
            );
        }

    setAccountUsers(email,appManager,appService,appPrice,appNetto,appCart,disable): Observable<User[]> {
      let params = new HttpParams();
      params = params.append('user', this.myUser);
      params = params.append('email', email);
      params = params.append('appManager', appManager);
      params = params.append('appService', appService);
      params = params.append('appPrice', appPrice);
      params = params.append('appNetto', appNetto);
      params = params.append('appCart', appCart);
      params = params.append('disable', disable);
      return this.httpClient.get<User[]>(Url.urlAccountPermission, { params })
          .pipe(
            tap (_ => console.log( _ )),
            catchError(this.handleError<User[]>('Get user ${id}'))
          );
    }


    setAccountLanguage(language): Observable<User[]> {
      let params = new HttpParams();
      params = params.append('user', this.myUser);
      params = params.append('language', language);
      return this.httpClient.get<User[]>(Url.urlAccountLanguage, { params })
          .pipe(
            tap (_ => console.log( _ )),
            catchError(this.handleError<User[]>('Get user ${id}'))
          );
    }

    setAccountAuthCode(code, email): Observable<User[]> {
      let params = new HttpParams();
      params = params.append('user', email);
      params = params.append('code', code);
      return this.httpClient.get<User[]>(Url.urlAccountAuthCode, { params })
          .pipe(
            tap (_ => console.log( _ )),
            catchError(this.handleError<User[]>('Get user ${id}'))
          );
    }

    resendAccountAuthCode(email, password): Observable<User[]> {
      let params = new HttpParams();
      params = params.append('user', email);
      params = params.append('password', password);
      return this.httpClient.get<User[]>(Url.urlAccountResendAuthCode, { params })
          .pipe(
            tap (_ => console.log( _ )),
            catchError(this.handleError<User[]>('Get user ${id}'))
          );
    }

    contactAccount(email, phone, partnerId): Observable<User[]> {
      let params = new HttpParams();
      params = params.append('user', email);
      params = params.append('phone', phone);
      params = params.append('partnerId', partnerId);
      return this.httpClient.get<User[]>(Url.urlAccountContactAccount, { params })
          .pipe(
            tap (_ => console.log( _ )),
            catchError(this.handleError<User[]>('Get user ${id}'))
          );
    }


    enableAccount(email, idMago, idOrdine): Observable<Abilita> {
      let params = new HttpParams();
      params = params.append('user', email);
      params = params.append('idMago', idMago);
      params = params.append('idOrdine', idOrdine);
      return this.httpClient.get<Abilita>(Url.urlAccountEnableAccount, { params })
          .pipe(
            tap ( resp => console.log( 'Il responso è: ' + resp.success)),
            catchError(this.handleError<Abilita>('Get user ${id}'))
          );
    }

    support(matricola, phone): Observable<Abilita> {
      let params = new HttpParams();
      params = params.append('user', this.myUser);
      params = params.append('matricola', matricola);
      params = params.append('phone', phone);
      return this.httpClient.get<Abilita>(Url.urlSupport, { params })
          .pipe(
            tap ( resp => console.log( 'Il responso è: ' + resp.success)),
            catchError(this.handleError<Abilita>('Get user ${id}'))
          );
    }

    resetAccountPsw(email, password, code): Observable<User[]> {
      let params = new HttpParams();
      params = params.append('user', email);
      params = params.append('password', password);
      params = params.append('code', code);
      return this.httpClient.get<User[]>(Url.urlAccountResetPassword, { params })
          .pipe(
            tap (_ => console.log( _ )),
            catchError(this.handleError<User[]>('Get user ${id}'))
          );
    }

    setAccountCountry(country): Observable<User[]> {
      let params = new HttpParams();
      params = params.append('user', this.myUser);
      params = params.append('country', country);
      return this.httpClient.get<User[]>(Url.urlAccountCountry, { params })
          .pipe(
            tap (_ => console.log( _ )),
            catchError(this.handleError<User[]>('Get user ${id}'))
          );
    }

    removePartner(): Observable<User[]> {
      let params = new HttpParams();
      params = params.append('user', this.myUser);
      return this.httpClient.get<User[]>(Url.urlAccountResetPartner, { params })
          .pipe(
            tap (_ => console.log( _ )),
            catchError(this.handleError<User[]>('Get user ${id}'))
          );
    }


  attivaLicenzaUser(codice,tipo): Observable<Licenza[]> {
    let params = new HttpParams();
    params = params.append('user', this.myUser);
    params = params.append('codice', codice);
    params = params.append('tipo', tipo);
    return this.httpClient.get<Licenza[]>(Url.urlLicenza, { params })
      .pipe(
        tap( licenza => {
          console.log(licenza);
        }),
        catchError(this.handleError<Licenza[]>('Get version', []))
      );
  }

  addLog(pagina,operazione, parametro): Observable<Licenza> {
    let params = new HttpParams();
    params = params.append('user', this.myUser);
    params = params.append('pagina', pagina);
    params = params.append('operazione', operazione);
    params = params.append('parametro', parametro);
    console.log (Url.urlLog+'?user='+this.myUser+'&pagina='+pagina);
    return this.httpClient.get<Licenza>(Url.urlLog, { params }).pipe();
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

}
